#loader {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -2.7em;
  margin-left: -2.7em;
  width: 5.4em;
  height: 5.4em;
  background-color: transparent;
}
#loader-overlay {
  z-index: 10000;
  background: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

#box {
  position: absolute;
  left: 0;
  top: 0;
  width: 2em;
  height: 2em;
  background-color: transparent;
  border: 0.25em solid blue;

  border-radius: 15%;
  transform: translate(0, 0em) rotate(-90deg);
  animation: push 2.5s cubic-bezier(0.79, 0, 0.47, 0.97) infinite;
}

@keyframes push {
  0% {
    transform: translate(0, 0) rotate(-45deg);
  }
  5% {
    transform: translate(0, 0) rotate(-50deg);
  }
  20% {
    transform: translate(1em, 0) rotate(47deg);
  }
  25% {
    transform: translate(1em, 0) rotate(45deg);
  }
  30% {
    transform: translate(1em, 0) rotate(40deg);
  }
  45% {
    transform: translate(2em, 0) rotate(137deg);
  }
  50% {
    transform: translate(2em, 0) rotate(135deg);
  }
  55% {
    transform: translate(2em, 0) rotate(130deg);
  }
  70% {
    transform: translate(3em, 0) rotate(217deg);
  }
  75% {
    transform: translate(3em, 0) rotate(220deg);
  }
  100% {
    transform: translate(0, 0) rotate(-225deg);
  }
}
