.align-left {
  text-align: left;
}
.button-small {
  border: 2px solid black;
  display: inline-block;
  border-radius: 5px;
  padding: 10px;
  font-weight: 400;
}
.button-small:hover {
  border: 2px solid black;
  cursor: pointer;
  background: black;
  color: white;
}

.button-red-small {
  border: 2px solid red;
  color: red;
  display: inline-block;
  border-radius: 5px;
  padding: 10px;
  font-weight: 400;
}
.button-red-small:hover {
  border: 2px solid red;
  cursor: pointer;
  background: red;
  color: white;
}
.dialog-title h6 {
  font-size: 16px;
}
