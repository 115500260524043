.drawer-paper {
  width: 60px;
  overflow-x: hidden;
}
.drawer-paper:hover {
  width: 260px;
}
.drawer-paper .list-text {
  display: none;
}
.drawer-paper:hover .list-text {
  display: inline-block;
}
.drawer-paper a {
  text-decoration: none;
}
