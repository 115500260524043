.login-card {
  max-width: 500px;
  margin: auto;
  margin-top: 100px;
  padding: 20px;
}

.login-input {
  margin-bottom: 30px !important;
}

.login-btn {
  height: 50px;
}
