.alignLeft {
  text-align: left;
}

.tableWrapper {
  margin-top: 12px;
}

.editCellWrapper {
  display: flex;
  column-gap: 10px;
}

.dateWrapper {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 10px;
}
